const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/about", "/about"],
    exact: true,
    component: "About",
  },  
  {
    path: ["/survey", "/melvin"],
    exact: true,
    component: "Survey",
  },
];


export default routes;
